(function($) {
  $.fn.conditionize = function(options) {
    var settings = $.extend(
      {
        hideJS: true
      },
      options
    );
    $.fn.containsAny = function(source,target) {
      var result = source.filter(function(item){ return target.indexOf(item) > -1});   
      return (result.length > 0);  
    }
    $.fn.eval = function(valueIs, valueShould, operator) {
      if (valueShould === true) {
        valueShould = 'true';
      } else if (valueShould === false) {
        valueShould = 'false';
      }
      switch (operator) {
        case '==':
          return valueIs == valueShould;
          break;
        case '!=':
          return valueIs != valueShould;
        case '<=':
          return parseInt(valueIs) <= parseInt(valueShould);
        case '<':
          return parseInt(valueIs) < parseInt(valueShould);
        case '>=':
          return parseInt(valueIs) >= parseInt(valueShould);
        case '>':
          return parseInt(valueIs) > parseInt(valueShould);
        case 'in':
          valueShouldArray = valueShould.split(',');
          //console.log("in evaluation: ")
          //console.log("value wanted: "+valueShould)
          //console.log("value selected: "+valueIs)
          for (let i = 0; i < valueShouldArray.length; i++) {
            valueShouldArray[i] = valueShouldArray[i].replace(/{COMMA}/g, ',');
          }
          return valueIs !== '' && valueShould !== '' && $.inArray(valueIs, valueShouldArray) !== -1;
        case 'radioIn':
          valueShouldArray = valueShould.split(',');
          for (let i = 0; i < valueShouldArray.length; i++) {
            valueShouldArray[i] = valueShouldArray[i].replace(/{COMMA}/g, ',');
          }
          return valueIs !== '' && valueShould !== '' && $.inArray(valueIs, valueShouldArray) !== -1;
        case 'multiple':
          if (valueIs !== null) {
            return valueIs !== '' && $.inArray(valueShould, valueIs) !== -1;
          }
        case 'multipleSelect2':
            if (valueIs !== null) {
              return valueIs !== '' && $.inArray(valueShould, valueIs) !== -1;
            }
        case 'multipleNotSelect2':
            if (valueIs !== null) {
              return valueIs !== '' && $.inArray(valueShould, valueIs) == -1;
            }
        case 'multipleIn':
          if (valueIs !== null) {
            return valueIs !== '' && $.fn.containsAny(valueIs, valueShould);
          }
      }
    };

    $.fn.resetInnerFieldsFromDataAttr = function(section) {
      section.find('select, input').each(function() {
        if ($(this).attr('type') == 'radio' || $(this).attr('type') == 'checkbox') {
          if ($(this).attr('data-old_value') === 'true') {
            $(this)
              .prop('checked', true)
              .trigger('click');
          }
        } else if ($(this).val() === '' && $(this).attr('data-old_value') !== '') {
          $(this).val($(this).attr('data-old_value'));
        }
      });
    };

    $.fn.saveInnerFieldsToDataAttr = function(section) {
      section.find('select, input').each(function() {
        if ($(this).attr('type') == 'radio' || $(this).attr('type') == 'checkbox') {
          $(this).attr('data-old_value', $(this).is(':checked'));
          $(this).prop('checked', false);
          $(this).removeClass('previousSelect');
        } else {
          $(this).attr('data-old_value', $(this).val());
          $(this).val('');
        }
      });
    };

    $.fn.showOrHide = function(listenTo, listenFor, operator, $section) {
      if ($(listenTo).is('select, input[type=text],input[type=hidden]') && $.fn.eval($(listenTo).val(), listenFor, operator)) {
        $section.show();
        //$.fn.resetInnerFieldsFromDataAttr($section);
      } else if (
        $(listenTo + ':checked').filter(function(idx, elem) {
          return $.fn.eval(elem.value, listenFor, operator);
        }).length > 0
      ) {
        $section.show();
        //$.fn.resetInnerFieldsFromDataAttr($section);
      } else {
        $section.hide();
        $section.find('select, input').each(function(){
          if ( ($(this).attr('type')=='radio') || ($(this).attr('type')=='checkbox') ) {
              if($(this).prop('checked')){
                $.fn.saveInnerFieldsToDataAttr($section);
                $(this).prop('checked', false).trigger('change');
              }else{
              }
          }else{
              if ($(this).val() !== ''){
                $.fn.saveInnerFieldsToDataAttr($section);
                $(this).val('').trigger('change');
              }else{
              }
              
          }
       });
      }
    };

    return this.each(function() {
      var cleanSelector = $(this)
        .data('cond-option')
        .toString()
        .replace(/(:|\.|\[|\]|,)/g, '\\$1');
      var listenTo = cleanSelector.substring(0, 1) == '#' ? cleanSelector : '[name=' + cleanSelector + ']';
      if (cleanSelector.substring(0, 1) !== '#' && $(this).data('cond-type') == 'select') {
        listenTo = 'select' + listenTo;
      }
      var listenFor = $(this).data('cond-value');
      var operator = $(this).data('cond-operator') ? $(this).data('cond-operator') : '==';
      var $section = $(this);

      //console.log("condition looking at field name"+cleanSelector)

      // Add input when you are listening to a text field...
      var events = 'change';
      if ($(listenTo).is('input[type=text]')) {
        events = 'change, input';
      }

      //Set up event listener
      $(listenTo).on(events, function() {
        $.fn.showOrHide(listenTo, listenFor, operator, $section);
        //console.log("event has fired: "+listenTo)
        //console.log("value for: "+listenTo+" is: "+$(listenTo).val())
      });
      //If setting was chosen, hide everything first...
      if (settings.hideJS) {
        $(this).hide();
      }
      //Show based on current value on page load
      $.fn.showOrHide(listenTo, listenFor, operator, $section);
    });
  };
})(jQuery);

window.TS.initConditional = function(selector) {
  if (typeof selector === 'undefined') {
    selector = '.conditional';
  }
  if ($(selector).length === 0) return;
  //console.log('Initializing Conditionize for ' + selector);
  $(selector).conditionize();
};
