import * as moment from 'moment/moment';
require( 'datatables.net' )(window, $);
require( 'datatables.net-bs' )(window, $);
require( 'datatables.net-buttons-bs' )(window, $);
require( 'datatables.net-buttons/js/buttons.colVis.js' )(window, $);
require( 'datatables.net-buttons/js/buttons.html5.js' )(window, $);
require( 'datatables.net-buttons/js/buttons.print.js' )(window, $);
require( 'datatables.net-buttons/js/buttons.flash.js' )(window, $);
require( 'datatables.net-fixedheader' )(window, $);
require( 'datatables.net-plugins/sorting/datetime-moment' );
import '../vendor/jquery.dataTables.yadcf'

import * as JSZip from "jszip";
window.JSZip = JSZip;
import 'pdfmake'

TS.initDataTables = function (selector, data) {
  if ($(selector).length == 0) return;
  if (data === undefined) data = {};
  if (data.order === undefined) {
    if ($(selector).data('sortlist') !== undefined) {
      data.order = $(selector).data('sortlist')
    }
  }
  if (data.dataSave === undefined) {
    data.dataSave = false;
  }else{
    data.dataSave = false;
  }
  if (data.dataFixed === undefined) {
    data.dataFixed = false;
  }else{
    data.dataFixed = true;
  }

  if (data.yadcfData === undefined){
    data.yadcfData = [{column_number : 0, filter_default_label: "-", html5_data: "data-filter",filter_reset_button_text: false}];
  }
  $.fn.dataTable.moment('DD-MMMM-YYYY');
  $.fn.dataTable.moment('DD-MMMM-YY');
  $.fn.dataTable.moment('DD-MMM-YYYY');
  $.fn.dataTable.moment('DD-MMM-YY');
  $.fn.dataTable.moment('DD-MMM-YYYY HH:mm:ss');
  $.fn.dataTable.moment('DD-MMM-YY HH:mm:ss');
  console.log('Initializing Data Table for ' + selector + ' (using selector: ' + $(selector).selector + ')')
  $(selector).dataTable({
    pageLength: 100,
    dom: 'lBfrtip',
    aLengthMenu: [
      [10, 25, 50, 100, 200, -1],
      [10, 25, 50, 100, 200, "All"]
    ],
    orderClasses: false,
    columnDefs: data.columnDefs,
    order: data.order,
    retrieve: true,
    "stateDuration": -1,
    stateSave: data.dataSave,
    fixedHeader: {
        header: data.dataFixed,
        footer: false
    },
    orderCellsTop: true,
    buttons: [
    data.adminVis,
    {
      extend: 'excel',
      text: 'Export to Excel',
      title: 'BRIS subject data',
      exportOptions: { orthogonal: 'filter' }
    }],
    preDrawCallback: function (settings) {
      // hide pagination if only one page
      var api = new $.fn.dataTable.Api(settings);
      var pagination = $(this)
        .closest('.dataTables_wrapper')
        .find('.dataTables_paginate');
      pagination.toggle(api.page.info().pages > 1);
    }
  }).yadcf(data.yadcfData,{filters_position: "header", filters_tr_index: 1});
};
