window.TS.inactivityTimer = function() {
  // CHECK IF THE PASSIVE OPTION IS SUPPorted by the broweser
  var passiveSupported = false;

  try {
    var options = Object.defineProperty({}, 'passive', {
      get: function() {
        passiveSupported = true;
      }
    });

    window.addEventListener('test', options, options);
    window.removeEventListener('test', options, options);
  } catch (err) {
    passiveSupported = false;
  }

  var passive_option = passiveSupported ? { passive: true } : false;
  document.removeEventListener('mousemove', window.TS.resetTimer);
  document.removeEventListener('mousedown', window.TS.resetTimer);
  document.removeEventListener('touchstart', window.TS.resetTimer);
  document.removeEventListener('click', window.TS.resetTimer);
  document.removeEventListener('scroll', window.TS.resetTimer);
  document.removeEventListener('keypress', window.TS.resetTimer);
  if (window.TS.timer !== undefined) clearTimeout(window.TS.timer);
  // run a min after load to ensure that everything is completely logged in the backend
  window.TS.timer = setTimeout(window.TS.PeriodicalQuery, 1 * 60 * 1000);
  document.addEventListener('mousemove', window.TS.resetTimer, passive_option);
  document.addEventListener('mousedown', window.TS.resetTimer, passive_option); // touchscreen presse
  document.addEventListener('touchstart', window.TS.resetTimer, passive_option);
  document.addEventListener('click', window.TS.resetTimer, passive_option); // touchpad click
  document.addEventListener('scroll', window.TS.resetTimer, passive_option); // scrolling with arrow key
  document.addEventListener('keypress', window.TS.resetTimer, passive_option);
};

// Adapted from https://github.com/pelargir/auto-session-timeout/
window.TS.PeriodicalQuery = function() {
  setTimeout(function() {
    $.ajax({
      type: 'POST',
      url: '/active',
      dataType: 'json',
      tryCount: 0,
      retryLimit: 3,
      success: function(response) {
        var has_timed_out = response.has_timed_out;
        if (has_timed_out === true || has_timed_out === 'true') {
          window.location.href = '/timeout';
        } else {
          window.TS.resetTimer();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        if (xhr.status === 401) {
          window.location.href = '/timeout';
        } else {
          this.tryCount++;
          if (this.tryCount <= this.retryLimit) {
            //try again
            $.ajax(this);
            return;
          }
          window.location.href = '/timeout';
        }
      }
    });
  }, 1 * 60 * 1000);
};

window.TS.resetTimer = function() {
  clearTimeout(window.TS.timer);
  window.TS.timer = setTimeout(window.TS.PeriodicalQuery, 20 * 60 * 1000);
};
